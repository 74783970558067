import { Suspense, useMemo } from 'react'
import jwt from '@/utils/jwt'

import createStackNavigator from '@/navigation/createStackNavigator'
import type {
  MainStackParamList,
  MainStackRouteName,
  GroupScreenProps
} from '@/types/navigation'

import LayoutContainer from '@/LayoutContainer'
import LoadingScreen from './LoadingScreen'
import View from '@/components/View'
import HomeNav from './buttons/HomeNav'
import BackButton from './buttons/BackButton'
import GoodsSearchForm from '@/components/goods/SearchForm'

import { headerHeight } from '@/styles'
import { t } from '@/i18n'
import { useSelector } from '@/store'
import Platform from '@/lib/Platform'

// MainStackScreens is split to web and native.
// difference is web uses React.lazy to use dynamic import and not on native.
// it might be better if we could do this by configuring build process but not sure how.
import Screens from './MainStackScreens'

const {
  AuthTokenScreen,
  AuthRedirectScreen,
  BarcodeScannerScreen,
  BookingCompleteScreen,
  BookingScreen,
  BookingsHistoryScreen,
  BookingsSearchScreen,
  CSVImportScreen,
  CSVImportEditScreen,
  CommerceLawScreen,
  EditGoodScreen,
  EditGroupFoldersScreen,
  EditGroupMembersScreen,
  EditGroupScreen,
  EditGroupSignUpScreen,
  EditUserManagersScreen,
  ExportGoodsQRCodeScreen,
  ExportGroupAuthTokensScreen,
  GoodBookingsHistoryScreen,
  GoodBookingsScreen,
  GoodScreen,
  GoodsImportSelectScreen,
  GoodReviewsScreen,
  GoodCommentsScreen,
  GoodTransferScreen,
  GroupAuthTokensScreen,
  GroupAuthTokensSpreadsheetImportScreen,
  GroupEmailInviteScreen,
  GroupGoodsPickerScreen,
  GroupNotificationSettingScreen,
  GroupPostEditScreen,
  GroupPostsScreen,
  GroupScreen,
  GroupSignUpScreen,
  GroupTransferScreen,
  HomeScreen,
  ISBNImportScreen,
  NewBookingNowScreen,
  NewBookingScreen,
  BulkBookingScreen,
  NewGoodScreen,
  NewGroupMemberScreen,
  NewGroupPostScreen,
  NewGroupScreen,
  NotFoundScreen,
  PaymentScreen,
  PaymentMethodScreen,
  PaymentHistoryScreen,
  PrivacyScreen,
  QRCodeScannerScreen,
  RedirectHomeScreen,
  SearchScreen,
  SearchSuggestScreen,
  SignInScreen,
  SignUpScreen,
  SignInMethodsScreen,
  EmailSignInScreen,
  LandingScreen,
  LandingBooksScreen,
  LanguagesScreen,
  SupportScreen,
  TermsScreen,
  TutorialScreen,
  UserDeleteScreen,
  UserEditScreen,
  UserGoodsPickerScreen,
  UserGroupsScreen,
  UserNotificationSettingScreen,
  UserReminderSettingScreen,
  UserScreen,
  UserSignUpScreen,
  LanguageSettingScreen,
  TimeZoneSettingScreen,
  SharedDeviceSettingScreen,
  MembershipSignInScreen,
  UpgradeScreen,

  // inside modal
  HelpModalScreen,
  ShareGoodScreen,
  FolderPickerScreen,
  PreviewScreen,

  // Tabs
  BookingsTabNavigator,
} = Screens

export const MainStack = createStackNavigator<MainStackParamList>()


import HeaderRightContainer from './HeaderRightContainer'

const headerLeft = ()=> {
  return(
    <View style={{height: headerHeight}}>
      <BackButton />
    </View>
  )
}

const headerLeftWithSearchForm =()=> {
  return (
    <View flex row style={{height: headerHeight, width: '100%'}}>
      <BackButton />
      <GoodsSearchForm />
    </View>
  )
}
 // set blank to avoid flickering title
const blank = { title: '' }

const Fallback = ()=> (<></>)

const MainStackScreen = ()=> {
  p('MainStackScreen')

  const token = useSelector(state => state.auth.token)
  let initialRouteName: MainStackRouteName = 'Landing'
  let initialParams = {}
  if(token) initialRouteName = 'Home' // logged in
  const parsedToken = useMemo(()=> jwt.parse(token), [token])
  if(parsedToken && parsedToken.jti && parsedToken.authorizable_id){
    initialRouteName = 'Group' // limited token
    initialParams = { id: parsedToken.authorizable_id } as GroupScreenProps['route']['params']
  }

  return (
    <Suspense fallback={<Fallback />}>
      <LayoutContainer>

        <MainStack.Navigator testID='navigator-main-stack'
          // Landing is separated to AppLandingScreen and SignUpScreen for native app and web.
          initialRouteName={initialRouteName}
          screenOptions={{
            ...(Platform.OS !== 'ios' ? {
              animation: 'none'
            } : {}),
            headerStyle: {
              // only applied on web,
              // you need to override header itself to achive settin height on native.
              // @ts-ignore
              height: headerHeight,
            },
            headerLeft,
            // createNativeStackNavigator options
            //stackAnimation: 'slide_from_right', // feel slow on android
          }}
        >

          <MainStack.Group>

            <MainStack.Screen
              name='Landing'
              component={LandingScreen}
              options={{
                headerShown: false,
                title: `${t('appName')} ${t('sales.catch1')}${t('sales.catch2')}`,
              }}
            />

            <MainStack.Screen
              name='LandingBooks'
              component={LandingBooksScreen}
              options={{
                headerShown: false,
                title: `${t('appName')} ${t('sales.catch1')}${t('sales.catch2')}`,
              }}
            />

            <MainStack.Screen
              name='Languages'
              component={LanguagesScreen}
              options={{ title: t('labels.language.setting') }}
            />

            <MainStack.Screen
              name='Home'
              component={HomeScreen}
              options={{
                // title is required for web to show as a tab title for browser
                title: Platform.OS === 'web' ? t('appName') : '',
                headerTitleStyle: {
                  // @ts-ignore web only
                  display: 'none',
                },
                headerRight: () => {
                  return (
                    <HeaderRightContainer>
                      <HomeNav />
                    </HeaderRightContainer>
                  )
                },
              }}
            />
            <MainStack.Screen
              name='Group'
              component={GroupScreen}
              initialParams={initialParams}
              options={{ title: t('appName') }}
              getId={({ params }) => params.id}
            />

            <MainStack.Screen
              name='SignUp'
              component={SignUpScreen}
              options={{
                header: ()=> null,
              }}
            />
            <MainStack.Screen
              name='UserSignUp'
              component={UserSignUpScreen}
              options={{
                title: t('actions.signUpAndStart'),
              }}
            />
            <MainStack.Screen
              name='SignIn'
              component={SignInScreen}
              options={{
                title: t('actions.signIn'),
              }}
            />
            <MainStack.Screen
              name='SignInMethods'
              component={SignInMethodsScreen}
              options={{
                title: t('actions.signIn'),
              }}
            />
            <MainStack.Screen
              name='EmailSignIn'
              component={EmailSignInScreen}
              options={{
                title: t('labels.user.loginWithEmail'),
              }}
            />
            <MainStack.Screen
              name='MembershipSignIn'
              component={MembershipSignInScreen}
              options={{
                title: t('actions.signIn'),
                header: ()=> null,
              }}
            />

            <MainStack.Screen
              name='RedirectHome'
              component={RedirectHomeScreen}
            />

            <MainStack.Screen
              name='User'
              component={UserScreen}
              options={blank}
              getId={({ params }) => params.id}
            />
            <MainStack.Screen
              name='UserEdit' // FIXME: rename to EditUser
              component={UserEditScreen}
              options={{
                title: `${t('models.user')}${t('general.setting')}`,
              }}
              getId={({ params }) => params.id}
            />
            <MainStack.Screen
              name='UserNotificationSetting'
              component={UserNotificationSettingScreen}
              options={{
                title: t('general.notificationSetting'),
              }}
            />
            <MainStack.Screen
              name='UserDelete' // FIXME: rename to DeleteUser
              component={UserDeleteScreen}
              options={{ title: t('labels.user.delete') }}
            />
            <MainStack.Screen
              name='UserGoodsPicker'
              component={UserGoodsPickerScreen}
              options={{ title: t('general.pick') }}
            />
            <MainStack.Screen
              name='UserReminderSetting'
              component={UserReminderSettingScreen}
              options={{ title: t('labels.reminder.setting') }}
            />

            <MainStack.Screen
              name='EditUserManagers'
              component={EditUserManagersScreen}
              options={{ title: t('labels.manager.edit') }}
            />

            <MainStack.Screen
              name='NewGood'
              component={NewGoodScreen}
              options={{ title: t('labels.good.new') }}
            />
            <MainStack.Screen
              name='Good'
              component={GoodScreen}
              options={blank}
              getId={({ params }) => params.id + params.source}
            />
            <MainStack.Screen
              name='EditGood'
              component={EditGoodScreen}
              options={{ title: t('labels.good.edit') }}
              getId={({ params }) => params.id}
            />
            <MainStack.Screen
              name='GoodBookingsHistory'
              component={GoodBookingsHistoryScreen}
              options={{ title: t('labels.booking.history') }}
            />
            <MainStack.Screen
              name='GoodBookings'
              component={GoodBookingsScreen}
              options={{ title: t('labels.booking.list') }}
            />
            <MainStack.Screen
              name='GoodReviews'
              component={GoodReviewsScreen}
              options={{ title: t('labels.review.list') }}
            />
            <MainStack.Screen
              name='GoodComments'
              component={GoodCommentsScreen}
              options={{ title: t('labels.comment.list') }}
            />

            <MainStack.Screen
              name='GoodsImportSelect'
              component={GoodsImportSelectScreen}
              options={{ title: t('actions.import') }}
            />
            <MainStack.Screen
              name='GoodTransfer'
              component={GoodTransferScreen}
              options={{ title: t('labels.good.transfer') }}
            />
            <MainStack.Screen
              name='CSVImport'
              component={CSVImportScreen}
              options={{ title: t('labels.good.spreadsheetImport') }}
            />
            <MainStack.Screen
              name='CSVImportEdit'
              component={CSVImportEditScreen}
              options={{ title: t('labels.good.spreadsheetImport')+t('general.edit') }}
            />
            <MainStack.Screen
              name='ISBNImport'
              component={ISBNImportScreen}
              options={{ title: t('labels.good.isbnImport') }}
            />

            <MainStack.Screen
              name='NewBooking'
              component={NewBookingScreen}
              options={{ title: t('labels.booking.new') }}
            />
            <MainStack.Screen
              name='NewBookingNow'
              component={NewBookingNowScreen}
              options={{ title: t('labels.booking.bookNow') }}
            />
            <MainStack.Screen
              name='BulkBooking'
              component={BulkBookingScreen}
              options={{ title: t('labels.booking.new') }}
            />
            <MainStack.Screen
              name='BookingComplete'
              component={BookingCompleteScreen}
              options={{ title: t('labels.booking.complete') }}
            />
            <MainStack.Screen
              name='Booking'
              component={BookingScreen}
              options={{ title: t('labels.booking.detail') }}
              getId={({ params }) => params.id}
            />
            <MainStack.Screen
              name='Bookings'
              component={BookingsTabNavigator}
              options={{ title: t('labels.booking.list') }}
            />
            <MainStack.Screen
              name='BookingsHistory'
              component={BookingsHistoryScreen}
              options={{ title: t('labels.booking.history') }}
            />
            <MainStack.Screen
              name='BookingsSearch'
              component={BookingsSearchScreen}
              options={{ title: t('general.search') }}
            />

            <MainStack.Screen
              name='Payment'
              component={PaymentScreen}
              options={{ title: t('general.payment') }}
              getId={({ params }) => params.id}
            />

            <MainStack.Screen
              name='PaymentMethod'
              component={PaymentMethodScreen}
              options={{ title: t('labels.payment.method') }}
            />

            <MainStack.Screen
              name='PaymentHistory'
              component={PaymentHistoryScreen}
              options={{ title: t('labels.payment.history') }}
            />

            <MainStack.Screen
              name='NewGroup'
              component={NewGroupScreen}
              options={{ title: t('labels.group.new') }}
            />
            <MainStack.Screen
              name='EditGroup'
              component={EditGroupScreen}
              options={{ title: t('labels.group.edit') }}
              getId={({ params }) => params.id}
            />
            <MainStack.Screen
              name='GroupTransfer'
              component={GroupTransferScreen}
              options={{ title: t('labels.group.transfer') }}
            />
            <MainStack.Screen
              name='EditGroupFolders'
              component={EditGroupFoldersScreen}
              options={{
                title: t('labels.group.editFolders'),
                // prevent onLongPress in the screen to trigger text selection on web iOS
                // @ts-ignore
                headerTitleStyle: { userSelect: 'none' },
                cardStyle: { userSelect: 'none' }
              }}
            />
            <MainStack.Screen
              name='UserGroups'
              component={UserGroupsScreen}
              options={{ title: t('labels.group.list') }}
            />
            <MainStack.Screen
              name='NewGroupMember'
              component={NewGroupMemberScreen}
              options={{ title: t('actions.addMember') }}
              getId={({ params }) => params.id}
            />
            <MainStack.Screen
              name='GroupEmailInvite'
              component={GroupEmailInviteScreen}
              options={{ title: t('labels.group.emailInvite') }}
            />
            <MainStack.Screen
              name='EditGroupMembers'
              component={EditGroupMembersScreen}
              options={{
                title: t('labels.membership.manage'),
                // prevent onLongPress in the screen to trigger text selection on web iOS
                // @ts-ignore
                headerTitleStyle: { userSelect: 'none' },
                cardStyle: { userSelect: 'none' }
              }}
            />
            <MainStack.Screen
              name='GroupNotificationSetting'
              component={GroupNotificationSettingScreen}
              options={{
                title: t('general.notificationSetting')
              }}
            />
            <MainStack.Screen
              name='ExportGoodsQRCode'
              component={ExportGoodsQRCodeScreen}
              options={{ title: t('labels.good.exportQRCodes') }}
            />
            <MainStack.Screen
              name='GroupSignUp'
              component={GroupSignUpScreen}
              options={{ title: t('labels.group.signUp') }}
            />
            <MainStack.Screen
              name='EditGroupSignUp'
              component={EditGroupSignUpScreen}
              options={{ title: t('labels.group.editGroupSignUp') }}
            />
            <MainStack.Screen
              name='NewGroupPost'
              component={NewGroupPostScreen}
              options={{
                title: t('labels.post.new'),
                gestureEnabled: false
              }}
            />
            <MainStack.Screen
              name='GroupPosts'
              component={GroupPostsScreen}
              options={{
                title: t('labels.group.postList'),
              }}
            />
            <MainStack.Screen
              name='GroupPostEdit'
              component={GroupPostEditScreen}
              options={{
                title: t('labels.post.edit')
              }}
            />
            <MainStack.Screen
              name='GroupGoodsPicker'
              component={GroupGoodsPickerScreen}
              options={{
                title: t('general.pick')
              }}
            />

            <MainStack.Screen
              name='GroupAuthTokens'
              component={GroupAuthTokensScreen}
              options={{
                title: t('labels.authToken.new')
              }}
            />
            <MainStack.Screen
              name='GroupAuthTokensSpreadsheetImport'
              component={GroupAuthTokensSpreadsheetImportScreen}
              options={{
                title: t('labels.authToken.spreadsheetImport')
              }}
            />
            <MainStack.Screen
              name='ExportGroupAuthTokens'
              component={ExportGroupAuthTokensScreen}
              options={{
                title: t('labels.authToken.printQR')
              }}
            />
            <MainStack.Screen
              name='AuthToken'
              component={AuthTokenScreen}
              options={{
                title: t('general.authenticating')
              }}
            />

            <MainStack.Screen
              name='BarcodeScanner'
              component={BarcodeScannerScreen}
              options={{ title: t('general.barcodeScanner') }}
            />
            <MainStack.Screen
              name='QRCodeScanner'
              component={QRCodeScannerScreen}
              options={{ title: t('general.codeScanner') }}
            />

            <MainStack.Screen
              name='Search'
              component={SearchScreen}
              options={{
                headerLeft: headerLeftWithSearchForm,
                title: Platform.OS === 'web' ? t('general.search') : '',
                // @ts-ignore
                headerTitleStyle: { display: 'none', },
              }}
            />
            <MainStack.Screen
              name='SearchSuggest'
              component={SearchSuggestScreen}
              options={{
                headerLeft: headerLeftWithSearchForm,
                title: Platform.OS === 'web' ? t('general.search') : '',
                // @ts-ignore
                headerTitleStyle: { display: 'none', },
              }}
            />

            <MainStack.Screen
              name='Upgrade'
              component={UpgradeScreen}
              options={{ title: t('labels.subscription.list') }}
            />

            <MainStack.Screen
              name='LanguageSetting'
              component={LanguageSettingScreen}
              options={{ title: t('labels.language.setting') }}
            />

            <MainStack.Screen
              name='TimeZoneSetting'
              component={TimeZoneSettingScreen}
              options={{ title: t('labels.timeZone.setting') }}
            />

            <MainStack.Screen
              name='SharedDeviceSetting'
              component={SharedDeviceSettingScreen}
              options={{ title: t('labels.device.sharedSetting') }}
            />

            <MainStack.Screen
              name='AuthRedirect'
              component={AuthRedirectScreen}
              options={{
                title: t('general.authenticating')
              }}
            />

            <MainStack.Screen
              name='Support'
              component={SupportScreen}
              options={{ title: t('general.support') }}
            />

            <MainStack.Screen
              name='Tutorial'
              component={TutorialScreen}
              options={{
                title: '',
                header: ()=> null,
                headerShown: false,
              }}
            />

            <MainStack.Screen
              name='Privacy'
              component={PrivacyScreen}
              options={{ title: t('general.privacyPolicy') }}
            />
            <MainStack.Screen
              name='Terms'
              component={TermsScreen}
              options={{ title: t('general.terms') }}
            />
            <MainStack.Screen
              name='CommerceLaw'
              component={CommerceLawScreen}
              options={{ title: t('general.commerceLaw') }}
            />

            <MainStack.Screen
              name="NotFound"
              component={NotFoundScreen}
              options={blank}
            />
          </MainStack.Group>

          <MainStack.Group screenOptions={{
            headerShown: false,
            animation: 'fade',
            ...Platform.select({
              web: {
                presentation: 'transparentModal',
              },
              android: {
                presentation: 'transparentModal',
              },
              ios: {
                // using transparentModal on iOS hangs the app
                stackPresentation: 'modal',
              },
            }),
          }}>

            <MainStack.Screen
              name='HelpModal'
              options={{ title: t('general.help') }}
              component={HelpModalScreen}
            />
            <MainStack.Screen
              name='ShareGood'
              options={{ title: t('general.share') }}
              component={ShareGoodScreen}
              getId={({ params }) => params.id}
            />
            <MainStack.Screen
              name='FolderPicker'
              options={{ title: t('labels.folder.pick') }}
              component={FolderPickerScreen}
            />
            <MainStack.Screen
              name='Preview'
              options={{ title: 'Preview' }}
              component={PreviewScreen}
            />

          </MainStack.Group>

        </MainStack.Navigator>

      </LayoutContainer>
    </Suspense>
  )
}

export default MainStackScreen
