import Configs from 'configs/Configs'

const base = Configs.apiBase

const supabaseId = Configs.supabase.id

const supabase = {
  functions: {
    inquiry: `https://${supabaseId}.functions.supabase.co/inquiry`
  }
}

const api = {
  supabase,
  graphql: ()=> `${Configs.graphqlBase}`,

  auth: {
    create:   ()=> `${base}/auth`,
    login:   ()=> `${base}/auth/login`,
  },

  users: {
    current:  ()=> `${base}/users/current`,
    device:   ()=> `${base}/users/device`,
    create:   ()=> `${base}/users`,
    index:    ()=> `${base}/users`,
    read:     (id)=> `${base}/users/${id}`,
    update:   (id)=> `${base}/users/${id}`,
    destroy:  (id)=> `${base}/users/${id}`,
    stripeAccountLink: ()=> `${base}/users/stripe_account_link`,
  },

  goods: {
    create:   ()=> `${base}/goods`,
    read:     (id)=> `${base}/goods/${id}`,
    index:    ()=> `${base}/goods`,
    update:   (id)=> `${base}/goods/${id}`,
    destroy:  (id)=> `${base}/goods/${id}`,
    bulkUpdate: ()=> `${base}/goods/bulk_update`,
    export:   ()=> `${base}/goods/export`,
    uploadJSON: ()=> `${base}/goods/upload_json`,
  },

  bookings: {
    create:   () => `${base}/bookings`,
    read:     (id)=> `${base}/bookings/${id}`,
    index:    ()=> `${base}/bookings`,
    update:   (id)=> `${base}/bookings/${id}`,
    ical:     (id)=> `${base}/bookings/${id}/ical`,
    export:   ()=> `${base}/bookings/export`,
    bulk:     ()=> `${base}/bookings/bulk`,
  },

  messages: {
    create:  () => `${base}/messages`,
  },

  groups: {
    create:    ()=> `${base}/groups`,
    read:    (id)=> `${base}/groups/${id}`,
    update:  (id)=> `${base}/groups/${id}`,
    destroy: (id)=> `${base}/groups/${id}`,
  },

  posts: {
    create:   ()=> `${base}/posts`,
    index:    ()=> `${base}/posts`,
    read:     (id)=> `${base}/posts/${id}`,
    destroy:  (id)=> `${base}/posts/${id}`,
    update:   (id)=> `${base}/posts/${id}`,
  },

  folders: {
    create:  () => `${base}/folders`,
    update: (id)=> `${base}/folders/${id}`,
    index:   () => `${base}/folders`,
    destroy: (id)=> `${base}/folders/${id}`,
  },

  memberships: {
    create:  () => `${base}/memberships`,
    index:   () => `${base}/memberships`,
    destroy: (id)=> `${base}/memberships/${id}`,
    update: (id)=> `${base}/memberships/${id}`,
    bulkUpdate: ()=> `${base}/memberships/bulk_update`,
  },

  inquries: {
    create: ()=> `${base}/inquries`,
  },

  authTokens: {
    create:  ()=>    `${base}/auth_tokens`,
    index:   () =>   `${base}/auth_tokens`,
    read:    (jti)=> `${base}/auth_tokens/${jti}`,
    destroy: (jti)=> `${base}/auth_tokens/${jti}`,
    update:  (jti)=> `${base}/auth_tokens/${jti}`,
    export:  ()=>    `${base}/auth_tokens/export`,
    import : ()=>    `${base}/auth_tokens/import`,
  },

  usersManagers: {
    create: ()=> `${base}/managers`,
    index: ()=> `${base}/managers`,
    destroy: (id) => `${base}/managers/${id}`
  },

  likes: {
    toggle: ()=> `${base}/likes/toggle`,
  },

  invites: {
    create: ()=> `${base}/invites`,
  },

  reviews: {
    create: ()=> `${base}/reviews`,
    update: (id)=> `${base}/reviews/${id}`,
    index:  ()=> `${base}/reviews`,
  },

  payments: {
    read: (id)=> `${base}/payments/${id}`,
    stripePubKey: ()=> `${base}/payments/stripe_pub_key`,
    sheet: ()=> `${base}/payments/sheet`,
    cancel: (id)=> `${base}/payments/cancel/${id}`,
    cancel_subscription: (id)=> `${base}/payments/cancel_subscription/${id}`,
    invoice: ()=> `${base}/payments/invoice`,
    index: ()=> `${base}/payments`,
  },

  payouts: {
    balance: ()=> `${base}/payouts/balance`,
    create:  ()=> `${base}/payouts`,
  },

  paymentMethods: {
    setupLink: ()=> `${base}/payment_methods/setup_link`,
    index: ()=> `${base}/payment_methods`,
    detachCard: (id)=> `${base}/payment_methods/detach_card/${id}`,
  },

  comments: {
    create:  ()=>   `${base}/comments`,
    index:   ()=>   `${base}/comments`,
    destroy: (id)=> `${base}/comments/${id}`,
  },

  subscriptions: {
    cancel: ()=> `${base}/subscriptions/cancel`,
  },

  books: {
    ndl: (isbn)=> `${base}/books/ndl/${isbn}`,
    thumbnail: (isbn)=> `${base}/books/ndl_thumbnail/${isbn}`,
    show: (isbn)=> `${base}/books/${isbn}`,
  },

  images: {
    s3PresignedUrl: ()=> `${base}/images/s3_presigned_url`,
  },

  analytics: {
    daily: ()=> `${base}/analytics/daily`,
  },

  pushTokens: {
    destroy: (id)=> `${base}/push_tokens/${id}`,
  },

}

export default api
