import { normalize, denormalize, schema } from 'normalizr'
import pluralize from 'pluralize'
import { capture } from '@/sentry'
import { Dispatch, GetState } from '@/store'
import type {
  User,
  Group,
  Folder
} from '@/types'

export {
  normalize,
  denormalize,
  schema,
  schemas,
}

import merge from 'deepmerge'

type State = {
  users: Record<string, User>
  messages: Record<string, any>
  bookings: Record<string, any>
  goods: Record<string, any>
  images: Record<string, any>
  groups: Record<string, Group>
  folders: Record<string, Folder>
  notificationSettings: Record<string, any>
  tags: Record<string, any>
  authTokens: Record<string, any>
  usersManagers: Record<string, any>
  reviews: Record<string, any>
  payments: Record<string, any>
  comments: Record<string, any>
}

type EntityName = keyof State

const initialState: State = {
  users: {},
  messages: {},
  bookings: {},
  goods: {},
  images: {},
  groups: {},
  folders: {},
  notificationSettings: {},
  tags: {},
  authTokens: {},
  usersManagers: {},
  reviews: {},
  payments: {},
  comments: {},
}

const types = {
  merge: 'entities/merge',
  remove: 'entities/remove',
  purge: 'entities/purge',
  append: 'entities/append',
} as const

type Merge = {
  type: typeof types.merge
  entities: any
}
type Remove = {
  type: typeof types.remove
  id: string
  name: EntityName
}
type Purge = {
  type: typeof types.purge
}
type Append = {
  type: typeof types.append
  id: string
  name: EntityName
  parentId: string
}
type Action = Merge | Remove | Purge | Append

const payment = new schema.Entity('payments', {})
const tag = new schema.Entity('tags', {}, { idAttribute: 'name' })
const image = new schema.Entity('images')
const notificationSetting = new schema.Entity('notificationSettings')
const post = new schema.Entity('posts', {
  goods: [new schema.Entity('goods', { images: [ image ] })],
  user: new schema.Entity('users'),
})

const review = new schema.Entity('reviews', {
  user: new schema.Entity('users'),
})
const comment = new schema.Entity('comments', {
  user: new schema.Entity('users'),
})

const group = new schema.Entity('groups', {
  logo: image,
  cover: image,
  latest_post: post,
  pinned_post: post,
  goods: [
    new schema.Entity('goods', {
      images: [ image ],
      user: new schema.Entity('users'),
    })
  ],
  folders: [ new schema.Entity('folders', {
    goods: [ new schema.Entity('goods', {
      images: [ image ],
      user: new schema.Entity('users'),
    }) ]
  },
  {
    idAttribute: 'slug'
  }) ],
  user: new schema.Entity('users'),
}, {
  idAttribute: 'slug'
})

const user = new schema.Entity('users', {
  avatar: image,
  goods: [
    new schema.Entity('goods', {
      images: [ image ],
      user: new schema.Entity('users'),
    })
  ],
  groups: [group],
  managing_users: [ new schema.Entity('users') ]
})

const usersManager = new schema.Entity('usersManagers', {
  user: user,
  manager: user,
})

const authToken = new schema.Entity('authTokens', {
  user: user
}, { idAttribute: 'jti' })

const message = new schema.Entity('messages', {
  user,
  booking: new schema.Entity('bookings'),
})

const listGood = new schema.Entity('goods', {
  user,
  images: [ image ],
})

const booking = new schema.Entity('bookings', {
  user,
  good: listGood,
  messages: [ message ],
  review,
  payments: [ payment, ],
  related_bookings: [
    new schema.Entity('bookings', {
      user,
      good: listGood,
    })
  ]
})

const good = new schema.Entity('goods', {
  user,
  images: [ image ],
  bookings: [ booking ],
  next_booking: booking,
  first_pending_booking: booking,
  folders: [ new schema.Entity('folders') ],
  comments: [ comment ],
})

const folder = new schema.Entity('folders', {
  goods: [ good ]
})

const membership = new schema.Entity('memberships', {
  user, group,
})

const schemas = {
  user,
  good,
  booking,
  message,
  group,
  folder,
  image,
  notificationSetting,
  tag,
  membership,
  post,
  authToken,
  auth_token: authToken,
  usersManager,
  users_manager: usersManager,
  review,
  payment,
  comment,
}
type AnySchema = typeof schemas[keyof typeof schemas]


export const append = (id: string, name: EntityName, parentId: string) => {
  return (dispatch: Dispatch) => {
    p('features/entities/append', { id, name, parentId })
    try{
      dispatch({ type: types.append, id, name, parentId })
    }catch(err){
      console.error(err)
    }
  }
}

export const remove = (id: string, name: EntityName) => {
  return (dispatch: Dispatch) => {
    p('features/entities/remove', { id, name })
    try{
      dispatch({ type: types.remove, id, name })
    }catch(err){
      console.error(err)
    }
  }
}

export const set = (data: any, schema: AnySchema | [AnySchema]) => {
  return (dispatch: Dispatch) => {
    if(!schema) throw `schema is ${schema}`
    p('features/entities/set', data, schema)
    try{
      const { entities } = normalize(data, schema)
      dispatch({ type: types.merge, entities })
    }catch(err){
      ppError(err)
      capture(err)
    }
  }
}

const overwriteArray = (destinationArray: any[], sourceArray: any[]) => sourceArray

const replace = (a: any, b: any) => b // later is always replace former
const customMerge = (key: string)=> {
  if(key === 'groups_folders'){
    return replace
  }
}

export const mergeOptions = {
  arrayMerge: overwriteArray,
  customMerge,
}

// pre define list of relationship for `append`
const appendTarget = {
  message: 'booking',
  folder: 'group',
}

export default (state = initialState, action: Action) => {
  try{
    switch (action.type) {
      case types.merge:
        return merge(state, action.entities, mergeOptions)
      case types.remove: {
        const target = state[action.name]
        // `_` will be removed entity
        const { [action.id]: _, ...remain } = target
        return {
          ...state,
          [action.name]: remain
        }
      }
      case types.append: {
        // @ts-ignore it's pretty hard to type this, fixit if possible
        const to = appendTarget[action.name] // booking
        // @ts-ignore it's pretty hard to type this, fixit if possible
        const target = state[pluralize(to)] // bookings
        const { [action.parentId]: parent, ...rest } = target
        const ids = parent[pluralize(action.name)] || [] // booking.messages
        return {
          ...state,
          [pluralize(to)]: { // bookings
            ...rest,
            [action.parentId]: { // 123
              ...parent,
              [pluralize(action.name)]: [ // messages
                ...ids, action.id
              ]
            },
          }
        }
      }
      case types.purge: {
        return initialState
      }
      default:
        return state
    }
  }catch(err){
    console.error(err)
  }
}
